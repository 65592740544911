.rdt input::placeholder {
  color: black; /* Mengubah warna placeholder menjadi hitam */
}
.rdt input {
  color: black !important; /* Mengubah warna teks value menjadi hitam */
}
.paginations {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pages-items-containers {
  background-color: #dde6ed;
  margin: 0 5px;
  /* padding: 5px 15px; */
  border: 0px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #474e68;
}

.pages-links-labels {
  color: #474e68;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 0px solid #ccc;
  display: inline-block;
  text-decoration: none;

  /* display: inline-block;
  padding: 10px 20px;
   background-color: #474e68; 
   color: #474e68; 
  text-decoration: none;
  border-radius: 10px;
  transition: background-color 0.3s ease; */
}

.pages-items-containers:hover {
  background-color: #4c6fc8;
}

.pages-links-labels:hover {
  color: #e2e6f0;
}

.actives {
  background-color: #4c6fc8;
  border: 0px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #ffffff;
  /* outline: 2px solid #4c6fc8; */
}

.actives:hover {
  background-color: #6e8bd4;
  text-decoration: none;
  color: #000000;
}

.activess {
  color: #ffffff;
}

.activess:hover {
  color: #e8edf9;
}

.activees .pages-links-labels {
  color: #ffffff;
}
